body{
    font-family: 'Montserrat' !important;
}

/* CSS HITS */
.ais-Hits-item {
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    padding: 1rem;
    box-shadow: 0 2px 5px 0 #e3e5ec;
    /* width: 1000px; */
    color: #808394;
    font-weight: 500;
    text-shadow: 0 1px #fff;
    border: 1px solid transparent;
    border-radius: 3px;
    margin-bottom: 3px;
    /* width: 1100px; */
  }
  
  .ais-Hits-list {
    top: 5px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
  }
  
  /* CSS PAGINATION */
  .ais-Pagination {
    margin-top: 1em;
    padding: 0px;
  }
  
  .ais-Pagination-list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0px;
  }
  
  .ais-Pagination-link {
    padding: 0.3rem 0.6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    color: #0096db;
    background-color: #fff;
    text-decoration: none;
  }
  
  .ais-Pagination-item--disabled .ais-Pagination-link {
    opacity: 0.6;
    cursor: not-allowed;
    color: #a5abc4;
  }
  
  .ais-Pagination-list > li + li {
    margin-left: 8px;
  }
  
  .ais-Pagination-item--selected .ais-Pagination-link {
    color: #fff;
    background-color: #0096db;
    border-color: #0096db;
    cursor: text;
    pointer-events: none;
}


.mb-4{
  margin-bottom: 0px !important;
}

.container-lg{
  margin-top: 24px;
}


/* CSS SEARCH BOX */
.ais-SearchBox-form{
  width: 100%;
  display: flex;
  justify-content: center;
}


.ais-SearchBox-input{
  width: 60%;
  font-size: 20px;
  border-radius: 5px 0px 0px 5px;
  border: solid 1px;
  padding-left: 5px;
  padding-right: 5px;
}

.ais-SearchBox-submit{
  border-radius: 0px 5px 5px 0px;
  border: solid 1px;
}

.ais-SearchBox-reset{
  margin-left: 10px;
  border-radius: 5px 5px 5px 5px;
  border: solid 1px;
}

.ais-SearchBox-input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input.middle:focus {
  outline-width: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}